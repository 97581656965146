/**
 * Domain identifier. Subdomain will be used by default (in production). Null sets default webshop ident
 * @type { Object<string, string> }
 */
export const domainToIdent = {
    'hotelgutscheine.urlaubsguru.de': 'urlaubsguru',
    'www.wunsch-reise.de': 'wunschgutschein',
    'www.netto-vorteile.de': 'nettovorteilswelt',
    'netto.animod.de': 'netto-discount',
    'www.animod.nl': 'www.animod.nl',
    'dev-nl.anilabs.de': 'www.animod.nl',
    'stage-nl.anilabs.de': 'www.animod.nl',
    'stage-nettovorteilswelt.anilabs.de': 'nettovorteilswelt',
    'stage-netto.anilabs.de': 'netto-discount',
    'www.animod.de': null,
    'www.kurz-in-urlaub.de': 'kurzinurlaub'
};

/**
 * Custom theme mapping
 * @type { Object<string, string> }
 */
export const identToTheme = {
    wunschreise: 'wunschgutschein',
    wgs: 'wunschgutschein',
    incentdeal: 'incent',
    douglas2: 'douglas',
};

/**
 * Custom theme mapping
 * @type { Object<string, string> }
 */
export const identToPricemode = {
    '2fuer1': 'perPerson',
    douglas: 'perPerson',
    douglas2: 'perPerson',
    mivo: 'perNight',
    incent: 'perNight',
    incentdeal: 'perNight',
    falktravel: 'perNight',
    femotion: 'perNight',
    compass: 'perNight',
    dpmd: 'perNight',
    vodafonedeal: 'perNight'
};
